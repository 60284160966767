import M from 'backbone.marionette';
import LineupPlayerItem from './linupplayeritem';
export default M.CollectionView.extend({
  template: _.template(''),
  className: 'list-group player-list',
  childView: LineupPlayerItem,
  childViewOptions: {
    showLogos: true,
    className: 'lineup-player-item list-group-item'
  },
  intitialize: function intitialize() {},
  onChildviewSelected: function onChildviewSelected(p) {
    this.triggerMethod('player:selected', p);
  },
  viewComparator: 'playerListOrder'
});