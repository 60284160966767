import M from 'backbone.marionette';
import T from './mobileplayerselect.html';
var ListItem = M.View.extend({
  template: _.template(T),
  tagName: 'option',
  modelEvents: {
    'change': 'render'
  },
  attributes: function attributes() {
    return {
      value: this.model.get('id')
    };
  }
});
export default M.CollectionView.extend({
  template: _.template(''),
  tagName: 'select',
  className: 'form-control',
  childView: ListItem,
  initialize: function initialize() {
    this.collection = new Backbone.Collection();
    this.collection.comparator = 'playerListOrder';
    this.collection.add({
      id: -1,
      name: '[Sélectionner...]'
    });

    for (var i = 0; i < this.getOption('players').length; i++) {
      this.collection.add(this.getOption('players').at(i));
    }

    this.listenTo(document.lue, 'playerselected', _.bind(this.checkCurrentPlayer, this));
  },
  events: {
    'change': 'onChange'
  },
  onChange: function onChange() {
    document.lue.playerSelection(this.collection.get(this.$el.val()), null);
  },
  checkCurrentPlayer: function checkCurrentPlayer() {
    if (document.lue.selected_player) this.$el.val(document.lue.selected_player.get('id'));else this.$el.val(-1);
  },
  viewComparator: 'playerListOrder'
});