import M from 'backbone.marionette';
import T from './lineupeditor.html';
import RE from './roster_element';
import PlayerListView from './playerlist';
import MultipleOVView from './multipleovview';
import OVView from './ovview';
import EstradesView from './estrades';
import colorpalette from './colorpalette';
import MobilePlayerSelect from './mobileplayerselect';
import StatsTable from './statstable';
import _ from 'underscore';
import Backbone from 'backbone';
export default M.View.extend({
  template: _.template(T),
  players: null,
  teams: null,
  admin: null,
  vhl_roster: null,
  ahl_roster: null,
  current_vhl_roster: null,
  current_ahl_roster: null,
  type: null,
  schedule: null,
  playoff: null,
  gamelist: null,
  team: null,
  lineuprows: null,
  lineupfilter: null,
  typefilter: null,
  linevalidation: null,
  ajustement_masse: null,
  nb_players: null,
  masse: 0,
  config: null,
  regions: {
    'g': '#g',
    'g2': '#g2',
    'extra': '#extra',
    'extra2': '#extra2',
    'evl1c': '#evl1c',
    'evl1ag': '#evl1ag',
    'evl1ad': '#evl1ad',
    'evl1dg': '#evl1dg',
    'evl1dd': '#evl1dd',
    'evl2c': '#evl2c',
    'evl2ag': '#evl2ag',
    'evl2ad': '#evl2ad',
    'evl2dg': '#evl2dg',
    'evl2dd': '#evl2dd',
    'evl3c': '#evl3c',
    'evl3ag': '#evl3ag',
    'evl3ad': '#evl3ad',
    'evl3dg': '#evl3dg',
    'evl3dd': '#evl3dd',
    'evl4c': '#evl4c',
    'evl4ag': '#evl4ag',
    'evl4ad': '#evl4ad',
    'evl4dg': '#evl4dg',
    'evl4dd': '#evl4dd',
    'pp5l1c': '#pp5l1c',
    'pp5l1ag': '#pp5l1ag',
    'pp5l1ad': '#pp5l1ad',
    'pp5l1dg': '#pp5l1dg',
    'pp5l1dd': '#pp5l1dd',
    'pp5l2c': '#pp5l2c',
    'pp5l2ag': '#pp5l2ag',
    'pp5l2ad': '#pp5l2ad',
    'pp5l2dg': '#pp5l2dg',
    'pp5l2dd': '#pp5l2dd',
    'pp4l1c': '#pp4l1c',
    'pp4l1ag': '#pp4l1ag',
    'pp4l1dd': '#pp4l1dd',
    'pp4l1dg': '#pp4l1dg',
    'pp4l2c': '#pp4l2c',
    'pp4l2ag': '#pp4l2ag',
    'pp4l2dd': '#pp4l2dd',
    'pp4l2dg': '#pp4l2dg',
    'pk4l1c': '#pk4l1c',
    'pk4l1ag': '#pk4l1ag',
    'pk4l1dd': '#pk4l1dd',
    'pk4l1dg': '#pk4l1dg',
    'pk4l2c': '#pk4l2c',
    'pk4l2ag': '#pk4l2ag',
    'pk4l2dd': '#pk4l2dd',
    'pk4l2dg': '#pk4l2dg',
    'pk3l1c': '#pk3l1c',
    'pk3l1d': '#pk3l1d',
    'pk3l1dd': '#pk3l1dd',
    'pk3l2c': '#pk3l2c',
    'pk3l2d': '#pk3l2d',
    'pk3l2dd': '#pk3l2dd',
    'player_list': {
      el: '#player_list',
      replaceElement: true
    },
    'player_ov': '#player_ov',
    'estrades': {
      el: '#estrades',
      replaceElement: true
    },
    'player_stats': '#player_stats',
    'mobile_player_select': '#mobile_player_select',
    'mobile_stats_table': '#mobile_stats_table',
    'mobile_player_ov': '#mobile_player_ov'
  },
  mode_crinque_stats: null,
  loaded: null,
  roasterLoaded: null,
  selected_player: null,
  clicked_position: null,
  selected_position: null,
  switching_position: null,
  version: null,
  ui: {
    'player_name': '#player_name',
    'player_nat': '#player_nat',
    'player_position': '#player_position',
    'player_sal': '#player_sal',
    'player_status': '#player_status',
    'budget_progess': '#budget_progess',
    'budget_caption': '#budget_caption',
    'player_ballotage': '#player_ballotage',
    'total_pg': '#total_pg',
    'bt_all': '#bt_all',
    'bt_c': '#bt_c',
    'bt_ag': '#bt_ag',
    'bt_ad': '#bt_ad',
    'bt_d': '#bt_d',
    'bt_a': '#bt_a',
    'bt_g': '#bt_g',
    'crinque_stats': '#crinque_stats',
    'stats': '.stats',
    'pj': '.stats .pj',
    'b': '.stats .b',
    'a': '.stats .a',
    'pts': '.stats .pts',
    'mpp': '.stats .mpp',
    'diff': '.stats .diff',
    'pim': '.stats .pim',
    't': '.stats .t',
    'mee': '.stats .mee',
    'tjm': '.stats .tjm',
    'ban': '.stats .ban',
    'pan': '.stats .pan',
    'ajouter_estrades': '#ajouter_estrades',
    'ovlegend': '.ovlegend',
    'validation_message': '#validation_message',
    'warning_message': '#warning_message',
    'save': '#save',
    'dropdownoption': '#dropdownoption',
    'nb_players': '#nb_players',
    'active_f': '#active_f',
    'active_d': '#active_d',
    'active_g': '#active_g'
  },
  events: {
    'click .lineup-filter': 'onLineupFilter',
    'click .type-filter': 'onTypeFilter',
    'click #crinque_stats': 'onCrinqueStatsClick',
    'click @ui.ajouter_estrades': 'onAjouterEstradeClick',
    'click #save': 'onSauvegarderClick',
    'change #dropdownoption': 'onDropDownOptionChanged'
  },
  serializeData: function serializeData() {
    return {
      version: this.version,
      ajustement_masse: parseInt(this.model.get('ajustement_masse')),
      nb_players: this.nb_players
    };
  },
  initialize: function initialize() {
    this.type = this.getOption('type');
    this.players = new Backbone.Collection();
    this.players.comparator = 'name';
    this.listenTo(this.players, 'change:lineup_status', _.bind(this.onPlayerRosterStatusChanged, this));
    this.model = new Backbone.Model();
    this.config = new Backbone.Model(this.getOption('config'));
    this.version = this.getOption('version');
    var data = this.getOption('data');
    this.lineuprows = {};

    for (var s in this.regions) {
      switch (s) {
        case 'g':
        case 'g2':
          this.lineuprows[s] = {
            valid: false,
            type: 'g',
            primeline: false,
            line: null
          };
          break;

        case 'extra':
        case 'extra2':
          this.lineuprows[s] = {
            valid: false,
            type: 'extra',
            primeline: false,
            line: null
          };
          break;

        default:
          var t = s.substr(0, 3);

          switch (t) {
            case 'pp5':
            case 'pp4':
              this.lineuprows[s] = {
                valid: false,
                type: t,
                primeline: false,
                line: parseInt(s.substr(4, 1))
              };
              break;

            case 'pk4':
            case 'pk3':
              this.lineuprows[s] = {
                valid: false,
                type: t,
                primeline: false,
                line: parseInt(s.substr(4, 1))
              };
              break;

            case 'evl':
              switch (s.substr(3, 1)) {
                case '1':
                case '2':
                  this.lineuprows[s] = {
                    valid: false,
                    type: t,
                    primeline: true,
                    line: parseInt(s.substr(3, 1))
                  };
                  break;

                default:
                  this.lineuprows[s] = {
                    valid: false,
                    type: t,
                    primeline: false,
                    line: parseInt(s.substr(3, 1))
                  };
                  break;
              }

              break;

            default:
              break;
          }

          break;
      }
    }

    this.teams = new Backbone.Collection(this.getOption('teams'));
    this.team = this.getOption('team');
    this.admin = this.getOption('admin');
    this.setUpData(data);
    $(document).on('keydown', _.bind(this.onKeyDown, this));
    $(document).on('keyup', _.bind(this.onKeyUp, this));
  },
  ctrlDown: null,
  onKeyDown: function onKeyDown(event) {
    if (event.keyCode == 17) {
      this.ctrlDown = true;
      this.validate();
    }
  },
  onKeyUp: function onKeyUp(event) {
    if (event.keyCode == 17) {
      this.ctrlDown = false;
      this.validate();
    }
  },
  setUpData: function setUpData(data) {
    this.ajustement_masse = data.ajustement_masse;
    var lineup = data.lineup;
    var joueurs = data.joueurs;
    this.model.set(lineup);
    this.players.reset();

    for (var i in joueurs) {
      joueurs[i]['active'] = false;
      joueurs[i]['rfi'] = '0';
      joueurs[i]['blesse'] = false;

      switch (parseInt(joueurs[i]['roster_status'])) {
        case 0:
        case 100:
        case 200:
          switch (parseInt(joueurs[i]['lineup_status'])) {
            case 101:
            case 102:
            case 103:
            case 104:
            case 105:
            case 106:
            case 107:
            case 201:
            case 202:
            case 203:
            case 204:
            case 205:
            case 206:
            case 207:
              joueurs[i]['lineup_status'] = 0;
              joueurs[i]['rfi'] = '1';
              break;

            default:
              break;
          }

          break;

        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
        case 107:
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
        case 207:
          joueurs[i]['blesse'] = true;
          break;

        default:
          break;
      }

      this.players.add(joueurs[i]);
    }

    var order = 0,
        p = null;

    for (var _i = 0; _i < this.players.length; _i++) {
      order++;
      p = this.players.at(_i);

      switch (p.get('position')) {
        case 'C':
          p.set({
            'c_order': order,
            'ad_order': order + 100,
            'ag_order': order + 100,
            'd_order': order + 200,
            'role': 'F'
          });
          break;

        case 'AG':
          p.set({
            'c_order': order + 100,
            'ad_order': order + 100,
            'ag_order': order,
            'd_order': order + 200,
            'role': 'F'
          });
          break;

        case 'AD':
          p.set({
            'c_order': order + 100,
            'ad_order': order,
            'ag_order': order + 100,
            'd_order': order + 200,
            'role': 'F'
          });
          break;

        case 'D':
          p.set({
            'c_order': order + 200,
            'ad_order': order + 200,
            'ag_order': order + 200,
            'd_order': order,
            'role': 'D'
          });
          break;

        case 'G':
          p.set({
            'c_order': order + 300,
            'ad_order': order + 300,
            'ag_order': order + 300,
            'd_order': order + 300,
            'role': 'G'
          });
          break;
      }
    }

    this.orderPlayerList();
  },
  canPlay: function canPlay(player) {
    if (parseInt(player.get('gp_total')) >= 92) return false;
    if (this.type == 'ahl') return true;

    switch (parseInt(player.get('roster_status'))) {
      case 0:
      case 100:
      case 200:
        return !parseInt(player.get('susp'));
        break;

      default:
        return false;
        break;
    }
  },
  loadData: function loadData(teamname) {
    $.ajax({
      url: window.site_url('gmeditor/team_data/' + teamname),
      success: _.bind(this.onDataSuccess, this),
      error: _.bind(this.onDataError, this)
    });
  },
  validating: null,
  validate: function validate() {
    if (this.validating == true) return;
    var active = {
      f: 0,
      d: 0,
      g: 0
    };
    this.validating = true;
    var that = this,
        is_active = false;
    var erreurs = [];
    var warns = [];
    var incomplete_pos = [];
    this.nb_players = 0;
    var restriction_checks = {};
    this.players.each(function (model) {
      is_active = false;
      var id = parseInt(model.get('id'));
      restriction_checks[id] = {
        lines: [],
        pp5_lines: 0,
        pp4_lines: 0,
        pk4_lines: 0,
        pk3_lines: 0,
        prime_lines: 0,
        ev_lines: [],
        ahl_primelines: 0,
        ahl_primedef: 0
      };

      for (var s in that.lineuprows) {
        if (parseInt(that.model.get(s)) == id) {
          restriction_checks[id].lines.push(s);

          switch (that.lineuprows[s].type) {
            case 'pp5':
              restriction_checks[id].pp5_lines++;
              break;

            case 'pp4':
              restriction_checks[id].pp4_lines++;
              break;

            case 'evl':
              restriction_checks[id].ev_lines.push(that.lineuprows[s].line);

              if (that.lineuprows[s].primeline) {
                restriction_checks[id].prime_lines++;
              }

              switch (s.substr(-2)) {
                case 'dd':
                case 'dg':
                  if (that.lineuprows[s].line <= 2) {
                    restriction_checks[id].ahl_primedef++;
                  }

                  break;

                default:
                  if (that.lineuprows[s].line <= 3) {
                    restriction_checks[id].ahl_primelines++;
                  }

                  break;
              }

              break;

            case 'pk4':
              restriction_checks[id].pk4_lines++;
              break;

            case 'pk3':
              restriction_checks[id].pk3_lines++;
              break;
          }

          is_active = true;
        }
      }

      if (is_active) {
        model.set('active', true);
        that.nb_players++;

        if (model.get('AD') == '1' || model.get('AG') == '1' || model.get('C') == '1') {
          active.f++;
        } else if (model.get('D') == '1') {
          active.d++;
        } else if (model.get('G') == '1') {
          active.g++;
        }

        if (!that.config.get('preseason') && model.get('gp_total') >= 92) {
          erreurs.push('' + model.get('name') + ' a joué le nombre maximum de parties autorisé (92). Veuillez le retirer de l\'alignement.');
        }

        if (!that.config.get('preseason') && that.type === 'ahl' && !model.get('blesse') && !parseInt(model.get('susp')) && model.get('cant_assign_ahl')) {
          erreurs.push('' + model.get('name') + ' ne peut pas jouer AHL selon ses modalités de contrat : <em>' + model.get('cant_assign_ahl') + '</em>. Veuillez le retirer de l\'alignement.');
        }

        switch (parseInt(model.get('roster_status'))) {
          case 201:
          case 202:
          case 203:
          case 204:
          case 205:
          case 206:
          case 101:
          case 102:
          case 103:
          case 104:
          case 105:
          case 106:
            if (that.type === 'vhlq') erreurs.push(model.get('name') + ' est blessé. Veuillez le retirer de votre alignement');
            break;

          default:
            if (parseInt(model.get('susp'))) {
              if (that.type === 'vhlq') erreurs.push(model.get('name') + ' est suspendu. Veuillez le retirer de votre alignement');
            } else if (parseInt(model.get('lineup_status')) >= 100 && parseInt(model.get('lineup_status')) <= 106) {
              erreurs.push(model.get('name') + ' est dans les estrades. Veuillez le remplacer dans votre alignement');
            } else if (parseInt(model.get('lineup_status')) > 200) {
              model.set('lineup_status', '200');
            } else if (parseInt(model.get('lineup_status')) == 0) {
              model.set('lineup_status', 200);
            }

            break;
        }

        if (parseInt(model.get('roster_status')) == 0) {
          if (that.type === 'vhlq') if (!that.config.get('preseason')) warns.push(model.get('name') + ' sera promu pro');
        }
      } else {
        model.set('active', false);

        if (parseInt(model.get('roster_status')) == 0 && parseInt(model.get('lineup_status')) == 100) {
          if (that.type === 'vhlq') if (!that.config.get('preseason')) warns.push(model.get('name') + ' sera promu pro');
        }

        if (parseInt(model.get('lineup_status')) == 200) {
          model.set('lineup_status', 0);
        }

        if (!that.config.get('preseason')) {
          if (that.type === 'vhlq') {
            if (parseInt(model.get('lineup_status')) == 100) {} else {
              if (model.get('cant_assign_ahl') && !model.get('blesse')) {
                erreurs.push('' + model.get('name') + ' ne peut pas être descendu AHL selon ses modalités de contrat : <em>' + model.get('cant_assign_ahl') + '</em>. Veuillez placer ce joueur dans l\'alignement ou dans les estrades.');
              } else if (parseInt(model.get('roster_status')) >= 100 && parseInt(model.get('lineup_status')) == 0 && !model.get('blesse')) {
                if (model.get('waivers_ineliogible') == '1') {
                  warns.push(model.get('name') + ' sera descendu AHL');
                } else {
                  warns.push('<big><strong>Attention, ' + model.get('name') + ' sera soumis au ballotage !</strong></big>');
                }
              }
            }
          }
        }
      }

      if (that.type === 'vhlq' && parseInt(model.get('rfi'))) {
        warns.push(model.get('name') + ' retourne d\'une blessure.');
      }
    });
    this.ui.active_f.html(active.f);
    this.ui.active_d.html(active.d);
    this.ui.active_g.html(active.g);

    for (var s in this.lineuprows) {
      if (this.model.get(s) === null) {
        incomplete_pos.push(s);
      }
    }

    if (incomplete_pos.length) {
      erreurs.push('L\'alignement n\'est pas complet. Veuillez vous assurer de place un joueur à toutes les positions (' + incomplete_pos.join(' ,') + ')');
    }

    if (this.type == 'vhlq') {
      if (this.nb_players != 20) {
        erreurs.push('Il doit y avoir 18 joueurs et 2 gardien sur votre alignement (20 au total). Il y en a ' + this.nb_players + ' actuellement');
      }
    } else {
      if (this.nb_players < 15 || this.nb_players > 20) {
        erreurs.push('Il doit y avoir entre 13 et 18 joueurs et 2 gardien sur votre alignement (15 au total). Il y en a ' + this.nb_players + ' actuellement');
      }
    }

    this.ui.nb_players.text(this.nb_players);
    this.updateMasse();
    this.orderPlayerList();

    if (this.mode === 'vhlq' && this.masse > this.config.get('salarycap') * 1000000) {
      erreurs.push('Masse salariale excèdante !');
    }

    var doublons = [{
      name: 'lignes de gardien de but',
      pos: ['g', 'g2']
    }, {
      name: '1ère ligne d\'attaque',
      pos: ['evl1c', 'evl1ag', 'evl1ad', 'evl1dg', 'evl1dd']
    }, {
      name: '2e ligne d\'attaque',
      pos: ['evl2c', 'evl2ag', 'evl2ad', 'evl2dg', 'evl2dd']
    }, {
      name: '3e ligne d\'attaque',
      pos: ['evl3c', 'evl3ag', 'evl3ad', 'evl3dg', 'evl3dd']
    }, {
      name: '4e ligne d\'attaque',
      pos: ['evl4c', 'evl4ag', 'evl4ad', 'evl4dg', 'evl4dd']
    }, {
      name: '1ere ligne 5 vs 4 (AN)',
      pos: ['pp5l1c', 'pp5l1ag', 'pp5l1ad', 'pp5l1dg', 'pp5l1dd']
    }, {
      name: '2e ligne 5 vs 4 (AN)',
      pos: ['pp5l2c', 'pp5l2ag', 'pp5l2ad', 'pp5l2dg', 'pp5l2dd']
    }, {
      name: '1ere ligne 4 vs 3 (AN)',
      pos: ['pp4l1c', 'pp4l1ag', 'pp4l1dd', 'pp4l1dg']
    }, {
      name: '2e ligne 4 vs 3 (AN)',
      pos: ['pp4l2c', 'pp4l2ag', 'pp4l2dd', 'pp4l2dg']
    }, {
      name: '1ère ligne 4 vs 5 (DN)',
      pos: ['pk4l1c', 'pk4l1ag', 'pk4l1dd', 'pk4l1dg']
    }, {
      name: '2e ligne 4 vs 5 (DN)',
      pos: ['pk4l2c', 'pk4l2ag', 'pk4l2dd', 'pk4l2dg']
    }, {
      name: '1ère ligne 3 vs 4 (DN)',
      pos: ['pk3l1c', 'pk3l1d', 'pk3l1dd']
    }, {
      name: '2e ligne 3 vs 4 (DN)',
      pos: ['pk3l2c', 'pk3l2d', 'pk3l2dd']
    }];

    for (var _i2 = 0; _i2 < doublons.length; _i2++) {
      for (var j = 0; j < doublons[_i2]['pos'].length; j++) {
        for (var k = j + 1; k < doublons.length; k++) {
          if (this.model.get(doublons[_i2]['pos'][j]) == this.model.get(doublons[_i2]['pos'][k])) {
            var p = this.players.get(this.model.get(doublons[_i2]['pos'][j]));

            if (p) {
              k = doublons.length;
              j = doublons.length;
              erreurs.push(p.get('name') + ' est présent à 2 reprises dans la ' + doublons[_i2]['name']);
            }
          }
        }
      }
    }

    for (var _i3 = 5; _i3 < doublons.length; _i3++) {
      var ok = false;

      for (var j = 0; j < doublons[_i3]['pos'].length; j++) {
        var p = this.players.get(this.model.get(doublons[_i3]['pos'][j]));

        if (p && p.get('D') == '1') {
          ok = true;
        }
      }

      if (!ok) {
        erreurs.push('Veuillez placer au moin un défenseur dans la ' + doublons[_i3]['name']);
      }
    }

    var pp5fchecks = [],
        pp4fchecks = [],
        pp5dchecks = [],
        pp4dchecks = [],
        pk4fchecks = [],
        pk3fchecks = [],
        pk4dchecks = [],
        pk3dchecks = [];

    for (var _i4 in restriction_checks) {
      var p = this.players.get(_i4);

      if (restriction_checks[_i4].pp5_lines == 2) {
        if (p.get('role') == 'F') pp5fchecks.push(_i4);else if (p.get('role') == 'D') pp5dchecks.push(_i4);
      }

      if (restriction_checks[_i4].pp4_lines == 2) {
        if (p.get('role') == 'F') pp4fchecks.push(_i4);else if (p.get('role') == 'D') pp4dchecks.push(_i4);
      }

      if (restriction_checks[_i4].pk4_lines >= 2) {
        if (p.get('role') == 'F') pk4fchecks.push(_i4);else if (p.get('role') == 'D') pk4dchecks.push(_i4);
      }

      if (restriction_checks[_i4].pk3_lines >= 2) {
        if (p.get('role') == 'F') pk3fchecks.push(_i4);else if (p.get('role') == 'D') pk3dchecks.push(_i4);
      }
    }

    if (pp5fchecks.length > 1) {
      erreurs.push('Un seul attaquant peut être présent sur 2 ligne d\'avantage numérique à 5.');
    }

    if (pp5dchecks.length > 1) {
      erreurs.push('Un seul défenseur peut être présent sur 2 lignes d\'avantage numérique à 5.');
    }

    if (pp4fchecks.length > 1) {
      erreurs.push('Un seul attaquant peut être présent sur 2 ligne d\'avantage numérique à 4.');
    }

    if (pp4dchecks.length > 1) {
      erreurs.push('Un seul défenseur peut être présent sur 2 lignes d\'avantage numérique à 4.');
    }

    if (pk4fchecks.length > 1) {
      erreurs.push('Un seul attaquant peut être présent sur 2 lignes d\'infériorité numérique à 4.');
    }

    if (pk4dchecks.length > 1) {
      erreurs.push('Un seul défenseur peut être présent sur 2 lignes d\'infériorité numérique à 4.');
    }

    if (pk3fchecks.length > 1) {
      erreurs.push('Un seul attaquant peut être présent sur 2 lignes d\'infériorité numérique à 3.');
    }

    if (pk3dchecks.length > 1) {
      erreurs.push('Un seul défenseur peut être présent sur 2 lignes d\'infériorité numérique à 3.');
    }

    if (this.type == 'vhlq') {
      for (var _i5 in restriction_checks) {
        var p = this.players.get(_i5);

        if (p.get('role') == 'D') {
          if (restriction_checks[_i5].prime_lines >= 2) {
            erreurs.push(p.get('name') + ' ne peut pas être présent à deux endroits dans le 2 premiers trios');
          }

          if (restriction_checks[_i5].ev_lines.length >= 3) {
            erreurs.push(p.get('name') + ' ne peut être présent que sur 2 trios. Veuillez corriger.');
          }
        }
      }
    } else {
      for (var i in restriction_checks) {
        if (restriction_checks[i].ahl_primelines > 1) {
          var p = this.players.get(i);
          erreurs.push(p.get('name') + ' ne peut pas être présent à plus d\'un endroit sur les 3 premiers trios');
        }

        if (restriction_checks[i].ahl_primedef > 1) {
          var p = this.players.get(i);
          erreurs.push(p.get('name') + ' ne peut pas être présent à plus d\'un endroit sur les 2 premiers trios défensifs');
        }
      }
    }

    var joueurs_trouve = [];

    for (var i in restriction_checks) {
      var p = this.players.get(i);

      if (p.get('role') == 'F') {
        if (restriction_checks[i].ev_lines.length > 2) {
          if (this.type == 'vhlq') {
            erreurs.push(p.get('name') + ' ne peut se retrouver sur plus de 2 trios.');
          }
        } else if (this.type === 'vhlq' && restriction_checks[i].ev_lines.length == 2) {
          var l1 = restriction_checks[i].ev_lines[0];
          var l2 = restriction_checks[i].ev_lines[1];

          if (l1 == 1 && l2 == 4 || l1 == 2 && l2 == 4 || l1 == 3 && l2 == 4 || l1 == 4 && l2 == 1 || l1 == 4 && l2 == 2 || l1 == 4 && l2 == 3) {
            joueurs_trouve.push(i);
          } else {
            erreurs.push(p.get('name') + ' ne peut se retrouver sur ces 2 trios. Veuillez corriger');
          }
        }
      }
    }

    if (joueurs_trouve.length > 1) {
      erreurs.push('Un seul attaquant peut se retrouver sur deux trios ( 1/2/3 et 4 )');
    }

    for (var s in this.lineuprows) {
      if (this.lineuprows[s].valid == false) {
        erreurs.push('Un ou plusieurs éléments sont incorrects dans l\'alignement. Veuillez corriger là ioù le bouton apparaît en rouge');
        break;
      }
    }

    if (erreurs.length) {
      this.ui.validation_message.html('<h1>Sauvegarde impossible</h1><p class="erreurs">' + erreurs.join('</p><p class="erreurs">') + '</p>');
      if (!this.ctrlDown) this.ui.save.prop('disabled', true);else this.ui.save.prop('disabled', false);
    } else {
      this.ui.validation_message.html('');
      this.ui.save.prop('disabled', false);
    }

    if (warns.length) {
      this.ui.warning_message.html('<p class="warning">' + warns.join('</p><p class="warning">') + '</p>');
    } else this.ui.warning_message.html('');

    this.validating = false;
  },
  onDataSuccess: function onDataSuccess(response) {
    this.setUpData(response.lineup, response.joueurs);
  },
  onDataError: function onDataError() {
    alert('Une erreur de chargement a eu lieu');
  },
  onRender: function onRender() {
    this.listenTo(this.model, 'change', _.bind(this.onRosterChanged, this));
    var views = {};

    for (var s in this.regions) {
      switch (s) {
        case 'g':
        case 'g2':
        case 'extra':
        case 'extra2':
          views[s] = {
            type: s,
            line: null,
            pos: null,
            model: this.model,
            collection: this.players,
            lue: this
          };
          break;

        default:
          var t = s.substr(0, 3);

          switch (t) {
            case 'pp5':
            case 'pp4':
            case 'pk4':
            case 'pk3':
              views[s] = {
                type: t,
                line: s.substr(4, 1),
                pos: s.substr(5),
                model: this.model,
                collection: this.players,
                lue: this
              };
              break;

            case 'evl':
              views[s] = {
                type: s.substr(0, 2),
                line: s.substr(3, 1),
                pos: s.substr(4),
                model: this.model,
                collection: this.players,
                lue: this
              };
              break;

            default:
              break;
          }

          break;
      }
    }

    for (var s in views) {
      this.showChildView(s, new RE(views[s]));
    }

    this.showChildView('player_list', new PlayerListView({
      collection: this.players,
      lum: this
    }));
    this.showChildView('mobile_player_select', new MobilePlayerSelect({
      players: this.players,
      lum: this
    }));
    this.showChildView('mobile_stats_table', new StatsTable({}));
    this.showChildView('player_stats', new StatsTable({}));
    var that = this;
    this.$el.find('.ovs').each(function (el) {
      new MultipleOVView({
        el: el,
        model: that.model,
        collection: that.players
      }).render();
    });
    this.showChildView('estrades', new EstradesView({
      model: this.model,
      collection: this.players
    }));
    this.updateMasse();
    this.ui.ovlegend.html(colorpalette.legende());
    this.validate();
  },
  playerSelection: function playerSelection(player_model, pos) {
    if (this.selected_player != player_model) {
      this.selected_player = player_model;

      if (this.selected_player) {
        this.selected_player.trigger('selected');
        this.ui.player_name.text(this.selected_player.get('name'));
        this.ui.player_nat.text(this.selected_player.get('nat'));
        this.ui.player_position.text(this.selected_player.get('position') + ' ' + (this.selected_player.get('right_handed') == '1' ? 'droitier' : 'gaucher'));
        var s = parseInt(this.selected_player.get('salary')) / 1000000;
        this.ui.player_sal.text(s.toFixed(3) + ' M');
        this.ui.player_ballotage.html(this.selected_player.get('waivers_ineliogible') == '1' ? '<span class="label label-informatif">Non éligible</span>' : '<span class="label label-avertissement">Éligible</span>');
        var gp = '';

        if (parseInt(this.selected_player.get('gp')) > 0) {
          gp += '<img src="' + window.base_url() + 'js/images/vhlq.png" width="15" /> x ' + this.selected_player.get('gp') + ' ';
        }

        if (parseInt(this.selected_player.get('gp_ahl')) > 0) {
          gp += '<img src="' + window.base_url() + 'js/images/ahl.png" width="15" /> x ' + this.selected_player.get('gp_ahl') + ' ';
        }

        gp += '<span class="label label-' + (parseInt(this.selected_player.get('gp_total')) >= 92 ? 'danger' : 'success') + '">Total : ' + this.selected_player.get('gp_total') + '</span>';
        this.ui.total_pg.html(gp);
        var condition = '';

        switch (parseInt(this.selected_player.get('roster_status'))) {
          case 200:
            if (parseInt(this.selected_player.get('condition')) < 100) {
              condition += ' ' + this.selected_player.get('condition') + ' %';
            } else condition = '<span class="label label-success">Top shape !</span>';

            break;

          case 201:
          case 101:
            condition = '<span class="label label-avertissement">Blessé - Day to day</span>';
            break;

          case 202:
          case 102:
            condition = '<span class="label label-avertissement">Blessé - 1 à 2 semaines</span>';
            break;

          case 203:
          case 103:
            condition = '<span class="label label-avertissement">Blessé - 2 à 4 semaines</span>';
            break;

          case 204:
          case 104:
            condition = '<span class="label label-avertissement">Blessé - 4 à 6 semaines</span>';
            break;

          case 205:
          case 105:
            condition = '<span class="label label-avertissement">Blessé - 2 à 4 mois</span>';
            break;

          case 206:
          case 106:
            condition = '<span class="label label-avertissement">Blessé - durée indéterminée</span>';
            break;
        }

        if (parseInt(this.selected_player.get('susp'))) condition += '<span class="label label-avertissement">Suspendu</span>';
        if (parseInt(this.selected_player.get('rfi'))) condition += '<span class="label label-success">RFI - Returns from injury</span>';
        this.ui.player_status.html(condition);
        this.showChildView('player_ov', new OVView({
          model: this.selected_player
        }));
        this.showChildView('mobile_player_ov', new OVView({
          model: this.selected_player
        }));
        this.getChildView('mobile_stats_table').show(this.selected_player);
        this.getChildView('player_stats').show(this.selected_player);
        this.ui.ajouter_estrades.prop('disabled', false);
      } else {
        this.ui.player_name.text('Infos joueur');
        this.ui.player_nat.text('');
        this.ui.player_position.text('');
        this.ui.player_sal.text('');
        this.ui.player_status.text('');
        this.getRegion('player_ov').empty();
        this.ui.stats.hide();
        this.ui.ajouter_estrades.prop('disabled', true);
      }
    }

    this.trigger('playerselected', this.selected_player);

    if (this.selected_position != pos) {
      this.selected_position = pos;
      this.switching_position = null;
      this.model.trigger('position:selected', null);
    } else {
      this.selected_position = pos;

      if (this.selected_position != this.switching_position) {
        this.switching_position = this.selected_position;
        this.model.trigger('position:selected', this.selected_position);
      } else {
        this.switching_position = false;
        this.model.trigger('position:selected', null);
      }
    }
  },
  roasterChangeTimer: null,
  roasterChangeExec: function roasterChangeExec() {
    if (this.validating == true) return;
    if (this.validating !== true) this.validate();
    this.roasterChangeTimer = null;
  },
  onRosterChanged: function onRosterChanged() {
    if (this.validating == true) return;
    if (this.roasterChangeTimer !== null) return;else this.roasterChangeTimer = setTimeout(_.bind(this.roasterChangeExec, this), 1);
  },
  onPlayerRosterStatusChangedTimer: null,
  onPlayerRosterStatusChangedExec: function onPlayerRosterStatusChangedExec() {
    if (this.validating == true) return;
    if (this.validating !== true) this.validate();
    this.onPlayerRosterStatusChangedTimer = null;
  },
  onPlayerRosterStatusChanged: function onPlayerRosterStatusChanged() {
    if (this.validating == true) return;
    if (this.onPlayerRosterStatusChangedTimer !== null) return;else this.onPlayerRosterStatusChangedTimer = setTimeout(_.bind(this.onPlayerRosterStatusChangedExec, this), 1);
  },
  updateMasse: function updateMasse() {
    var player_ids = [],
        p = null;
    var masse = 0;

    for (var i = 0; i < this.players.length; i++) {
      p = this.players.at(i);

      switch (parseInt(p.get('roster_status'))) {
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
          player_ids.push(p.get('id'));
          break;

        default:
          if (parseInt(p.get('lineup_status')) == 100 || parseInt(p.get('lineup_status')) == 200 || parseInt(p.get('susp'))) {
            player_ids.push(p.get('id'));
          }

          break;
      }
    }

    for (var i = 0; i < player_ids.length; i++) {
      if (this.players.get(player_ids[i])) masse += parseInt(this.players.get(player_ids[i]).get('salary'));
    }

    masse += parseInt(this.model.get('ajustement_masse'));
    this.ui.budget_progess.css({
      width: masse / (this.config.get('salarycap') * 1000000) * 100 + '%'
    });
    this.ui.budget_caption.text((masse / 1000000).toFixed(2) + ' M');
    this.masse = masse;
  },
  onLineupFilter: function onLineupFilter(event) {
    var filter = $(event.currentTarget).attr('data-filter');
    var elements = this.$el.find('.player-list .lineup-player-item');
    $(elements).show();

    switch (filter) {
      case 'ALL':
        this.lineupfilter = '';
        break;

      case 'A':
        this.lineupfilter = '.pos-D, .pos-G';
        $(elements).filter('.pos-D, .pos-G').hide();
        break;

      case 'C':
        this.lineupfilter = ':not(.pos-C)';
        $(elements).filter(':not(.pos-C)').hide();
        break;

      case 'AD':
        this.lineupfilter = ':not(.pos-AD)';
        $(elements).filter(':not(.pos-AD)').hide();
        break;

      case 'AG':
        this.lineupfilter = ':not(.pos-AG)';
        $(elements).filter(':not(.pos-AG)').hide();
        break;

      case 'D':
        this.lineupfilter = ':not(.pos-D)';
        $(elements).filter(':not(.pos-D)').hide();
        break;

      case 'G':
        this.lineupfilter = ':not(.pos-G)';
        $(elements).filter(':not(.pos-G)').hide();
        break;
    }

    if (this.lineupfilter) $(elements).filter(this.lineupfilter).hide();
    if (this.typefilter) $(elements).filter(this.typefilter).hide();
    this.$el.find('.lineup-filter').removeClass('btn-primary').addClass('btn-default');
    $(event.currentTarget).removeClass('btn-default').addClass('btn-primary');
  },
  onTypeFilter: function onTypeFilter(event) {
    var filter = $(event.currentTarget).attr('data-filter');
    var elements = this.$el.find('.player-list .lineup-player-item');
    $(elements).show();

    switch (filter) {
      case 'ALL':
        this.typefilter = null;
        break;

      case 'AHL':
        this.typefilter = '.vhlq';
        break;

      case 'VHLQ':
        this.typefilter = '.ahl';
        break;
    }

    if (this.lineupfilter) $(elements).filter(this.lineupfilter).hide();
    if (this.typefilter) $(elements).filter(this.typefilter).hide();
    this.$el.find('.type-filter').removeClass('btn-primary').addClass('btn-default');
    $(event.currentTarget).removeClass('btn-default').addClass('btn-primary');
  },
  onCrinqueStatsClick: function onCrinqueStatsClick() {
    if (this.mode_crinque_stats !== true) {
      this.mode_crinque_stats = true;
      this.ui.crinque_stats.removeClass('btn-default').addClass('btn-primary');
      this.$el.addClass('crinke');
    } else {
      this.mode_crinque_stats = false;
      this.ui.crinque_stats.removeClass('btn-primary').addClass('btn-default');
      this.ui.crinque_stats.find('.glyphicon').removeClass('.glyphicon-check').addClass('.glyphicon-unchecked');
      this.$el.removeClass('crinke');
    }
  },
  onAjouterEstradeClick: function onAjouterEstradeClick() {
    if (this.selected_player) {
      for (var s in this.lineuprows) {
        if (this.model.get(s) == this.selected_player.get('id')) {
          this.model.set(s, null);
        }
      }

      this.selected_player.set('lineup_status', 100);
    }
  },
  onSauvegarderClick: function onSauvegarderClick() {
    var status = [],
        p = null;

    for (var i = 0; i < 50; i++) {
      p = this.players.get(i);

      if (p) {
        status.push(p.get('lineup_status'));
      } else {
        status.push(0);
      }
    }

    this.model.set('status', status);
    $.ajax({
      type: 'POST',
      data: {
        submit: 'submit',
        lines: this.model.toJSON()
      },
      success: function success(response) {
        if (response === true) alert('Alignement sauvegardée');else alert('Une erreur a eu lieu');
      },
      error: function error() {
        alert('Impossible de sauvegarder l\'alignement');
      }
    });
  },
  orderPlayerList: function orderPlayerList() {
    var vhlq_order = 1;
    var cantplay_order = 100;
    var ahl_order = 200;
    this.players.forEach(function (model) {
      if (parseInt(model.get('susp')) || parseInt(model.get('roster_status')) > 200 || parseInt(model.get('lineup_status')) >= 100 && parseInt(model.get('lineup_status')) <= 106) {
        model.set('playerListOrder', cantplay_order++);
      } else if (parseInt(model.get('roster_status')) == 0) {
        model.set('playerListOrder', ahl_order++);
      } else {
        model.set('playerListOrder', vhlq_order++);
      }
    });
  },
  onDropDownOptionChanged: function onDropDownOptionChanged() {
    if (this.ui.dropdownoption.is(':checked')) this.$el.addClass('avec-dropdown');else this.$el.removeClass('avec-dropdown');
  }
});