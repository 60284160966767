import M from 'backbone.marionette';
import OVView from './ovview';
export default M.View.extend({
  template: _.template('<div></div>'),
  positions: null,
  attrs: null,
  modelEvents: null,
  collection: null,
  regions: {
    'c': 'div'
  },
  initialize: function initialize() {
    this.attrs = ['IT', 'SP', 'ST', 'EN', 'DU', 'DI', 'SK', 'PA', 'PC', 'DF', 'SC', 'EX', 'LD', 'webov'];
    this.positions = this.$el.attr('data-rows').split(' ');
    this.all_specs = this.$el.hasClass('all');
    this.collection = this.getOption('collection');

    for (var i in this.positions) {
      this.listenTo(this.model, 'change:' + this.positions[i], _.bind(this.onChanged, this));
    }
  },
  onChanged: function onChanged() {
    this.render();
  },
  onRender: function onRender() {
    var ovs = new Backbone.Collection();
    var avg = [],
        max = [],
        min = [],
        count = 0;

    for (var i in this.attrs) {
      avg[this.attrs[i]] = 0;
      max[this.attrs[i]] = 0;
      min[this.attrs[i]] = 100;
    }

    for (var i in this.positions) {
      if (this.model.get(this.positions[i])) {
        var p = this.collection.get(this.model.get(this.positions[i]));

        if (p) {
          count++;

          for (var a in this.attrs) {
            avg[this.attrs[a]] += parseInt(p.get(this.attrs[a]));

            if (this.all_specs) {
              if (parseInt(p.get(this.attrs[a])) > max[this.attrs[a]]) max[this.attrs[a]] = parseInt(p.get(this.attrs[a]));
              if (parseInt(p.get(this.attrs[a])) < min[this.attrs[a]]) min[this.attrs[a]] = parseInt(p.get(this.attrs[a]));
            }
          }
        }
      }
    }

    if (count) {
      for (var a in this.attrs) {
        avg[this.attrs[a]] = avg[this.attrs[a]] / count;
      }
    }

    min.type = 'MIN';
    if (this.all_specs) ovs.add(new Backbone.Model(min));
    avg.type = 'MOY';
    ovs.add(new Backbone.Model(avg));
    max.type = 'MAX';
    if (this.all_specs) ovs.add(new Backbone.Model(max));
    this.showChildView('c', new OVView({
      collection: ovs
    }));
  }
});