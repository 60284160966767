import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _M$View$extend;

import M from 'backbone.marionette';
import T from './roster_element.html';
import PlayerItem from './player_item';
import LineupPlayerItem from './linupplayeritem';
export default M.View.extend((_M$View$extend = {
  template: _.template(T),
  regions: {
    'liste': {
      el: '.dropdown-menu',
      replaceElement: true
    },
    'player_item': {
      el: '.player_item',
      replaceElement: true
    }
  },
  ui: {
    'player_position': '#player_position',
    'player_name': '#player_name',
    'selection': '.selection-button',
    'playercell': '.player-cell'
  },
  modelEvents: {
    'position:selected': 'onPositionSeleted'
  },
  events: {
    'click .selection-button': 'onSelectionClicked',
    'click .name_link': 'onNameLinkClicked'
  },
  type: null,
  pos: null,
  line: null,
  row: null,
  filter: null,
  comparator: null,
  isinswitchmode: null,
  initialize: function initialize() {
    this.listenTo(this.getOption('lue'), 'playerselected', _.bind(this.onPlayerSelected, this));
    this.listenTo(this.getOption('collection'), 'change', _.bind(this.onCollectionChanged, this));
    this.type = this.getOption('type');
    this.pos = this.getOption('pos');
    this.line = this.getOption('line');
    this.row = (this.type ? this.type.toString() : '') + (this.line ? 'l' + this.line.toString() : '') + (this.pos ? this.pos.toString() : '');
    var that = this;

    if (this.type == 'g' || this.type == 'g2') {
      this.filter = function (model) {
        return model.get('G') == '1' && document.lue.canPlay(model);
      };
    } else {
      switch (this.type) {
        case 'g':
        case 'g2':
          this.filter = function (model) {
            return model.get('G') == '1' && document.lue.canPlay(model);
          };

          break;

        case 'extra':
        case 'extra2':
          this.filter = function (model) {
            return model.get('G') != '1' && document.lue.canPlay(model);
          };

          break;

        case 'ev':
          switch (this.pos) {
            case 'c':
              this.filter = function (model) {
                return model.get('C') == '1' && document.lue.canPlay(model);
              };

              break;

            case 'ag':
              this.filter = function (model) {
                return model.get('AG') == '1' && document.lue.canPlay(model);
              };

              break;

            case 'ad':
              this.filter = function (model) {
                return model.get('AD') == '1' && document.lue.canPlay(model);
              };

              break;

            case 'dg':
            case 'dd':
            case 'd':
              this.filter = function (model) {
                return model.get('D') == '1' && document.lue.canPlay(model);
              };

              break;

            default:
              this.filter = function (model) {
                return false;
              };

              break;
          }

          break;

        case 'pp5':
        case 'pp4':
          switch (this.pos) {
            case 'c':
              this.filter = function (model) {
                return model.get('C') == '1' && document.lue.canPlay(model);
              };

              break;

            case 'ag':
            case 'ad':
              this.filter = function (model) {
                return (model.get('C') == '1' || model.get('AD') == '1' || model.get('AG') == '1') && document.lue.canPlay(model);
              };

              break;

            case 'dg':
            case 'dd':
            case 'd':
              this.filter = function (model) {
                return model.get('G') != '1' && document.lue.canPlay(model);
              };

              break;

            default:
              this.filter = function (model) {
                return false;
              };

              break;
          }

          break;

        case 'pk4':
        case 'pk3':
          switch (this.pos) {
            case 'c':
            case 'ag':
            case 'ad':
              this.filter = function (model) {
                return (model.get('AD') == '1' || model.get('C') == '1' || model.get('AG') == '1') && document.lue.canPlay(model);
              };

              break;

            case 'dg':
            case 'dd':
            case 'd':
              this.filter = function (model) {
                return model.get('G') != '1' && document.lue.canPlay(model);
              };

              break;

            default:
              this.filter = function (model) {
                return false;
              };

              break;
          }

          break;

        default:
          switch (this.pos) {
            case 'c':
            case 'ag':
            case 'ad':
              this.filter = function (model) {
                return (model.get('AD') == '1' || model.get('AG') == '1' || model.get('C') == '1') && document.lue.canPlay(model);
              };

              break;

            case 'dg':
            case 'dd':
            case 'd':
              this.filter = function (model) {
                return model.get('D') == '1' && document.lue.canPlay(model);
              };

              break;

            default:
              this.filter = function (model) {
                return false;
              };

              break;
          }

          break;
      }
    }

    this.listenTo(this.model, 'change:' + this.row, _.bind(this.render, this));
  },
  onClickedPosition: function onClickedPosition(pos) {
    if (pos = this.pos) {
      this.$el.addClass('selected');
    } else this.$el.removeClass('selected');
  },
  validate: function validate() {
    if (this.model.get(this.row) !== null && this.getOption('collection').get(parseInt(this.model.get(this.row))) && this.filter(this.getOption('collection').get(parseInt(this.model.get(this.row)))) && document.lue.canPlay(this.getOption('collection').get(parseInt(this.model.get(this.row))))) {
      document.lue.lineuprows[this.row].valid = true;
      this.ui.selection.removeClass('btn-danger').addClass('btn-default');
    } else {
      document.lue.lineuprows[this.row].valid = false;
      this.ui.selection.removeClass('btn-default').addClass('btn-danger');
    }
  },
  onCollectionChanged: function onCollectionChanged(model) {
    if (model.get('id') == this.model.get(this.row)) {
      this.validate();
      this.updateSelectionButton();
    }
  },
  onRender: function onRender() {
    var comparator = '';
    this.showChildView('liste', new M.CollectionView({
      tagName: 'ul',
      className: 'dropdown-menu player_choice',
      collection: this.getOption('collection'),
      filter: this.filter,
      viewComparator: this.comparator,
      childView: PlayerItem
    }));

    if (this.model.get(this.row) !== null && parseInt(this.model.get(this.row)) >= 0) {
      var player = this.getOption('collection').get(parseInt(this.model.get(this.row)));

      if (player) {
        this.showChildView('player_item', new LineupPlayerItem({
          model: player,
          pos: this.row
        }));
      }
    }

    this.updateSelectionButton();
    this.validate();

    if (this.getOption('lue').selected_player && this.getOption('lue').selected_player.get('id') == this.model.get(this.row)) {
      this.ui.playercell.addClass('active');
    } else this.ui.playercell.removeClass('active');
  },
  onRosterStatusChanged: function onRosterStatusChanged(model) {
    if (parseInt(model.previous('lineup_status')) == 100) {
      this.showChildView('liste', new M.CollectionView({
        tagName: 'ul',
        className: 'dropdown-menu player_choice',
        collection: this.getOption('collection'),
        filter: this.filter,
        viewComparator: this.comparator,
        childView: PlayerItem
      }));
    }
  },
  onSelectionClicked: function onSelectionClicked(event) {
    var player = document.lue.selected_player;
    var current_player = this.getOption('collection').get(parseInt(this.model.get(this.row)));

    if (player && player != current_player) {
      if (document.lue.switching_position) {
        this.model.set(document.lue.switching_position, this.model.get(this.row));
      }

      this.model.set(this.row, player.get('id'));
      if (document.lue.canPlay(player)) player.set('lineup_status', '200');
      document.lue.playerSelection(player, null);
    }
  },
  updateSelectionButton: function updateSelectionButton() {
    if (this.isinswitchmode) {
      this.ui.selection.html('<span class="glyphicon glyphicon-transfer" aria-hidden="true"></span>').removeClass('btn-default').addClass('btn-success');
    } else {
      var sel = this.pos;

      switch (this.type) {
        case 'g':
          sel = 'G1';
          break;

        case 'g2':
          sel = 'G2';
          break;

        case 'extra':
          sel = '#1';
          break;

        case 'extra2':
          sel = '#2';
          break;

        default:
          switch (this.pos) {
            case 'ag':
              sel = 'LW';
              break;

            case 'ad':
              sel = 'RW';
              break;

            case 'dd':
              sel = 'RD';
              break;

            case 'dg':
            case 'd':
              sel = 'LD';
              break;

            default:
              break;
          }

          sel = sel.toUpperCase();
          break;
      }

      this.ui.selection.text(sel).addClass('btn-default').removeClass('btn-success');
    }

    var player = document.lue.selected_player;

    if (player) {
      if (player.get('id') == this.model.get(this.row)) {
        this.ui.selection.prop('disabled', true);
      } else if (this.type == 'g' || this.type == 'g2') {
        if (player.get('G') == '1') this.ui.selection.prop('disabled', false);else this.ui.selection.prop('disabled', true);
      } else {
        if (this.filter(player)) {
          this.ui.selection.prop('disabled', false);
        } else {
          this.ui.selection.prop('disabled', true);
        }
      }
    } else {
      this.ui.selection.prop('disabled', true);
    }
  },
  onChildviewPlayerSelected: function onChildviewPlayerSelected(model) {},
  onPlayerSelected: function onPlayerSelected(player, pos) {
    this.updateSelectionButton();
  }
}, _defineProperty(_M$View$extend, "onChildviewPlayerSelected", function onChildviewPlayerSelected(model) {
  this.model.set(this.row, model.get('id'));
  document.lue.playerSelection(model, null);
}), _defineProperty(_M$View$extend, "onPositionSeleted", function onPositionSeleted(pos) {
  this.isinswitchmode = pos == this.row;
  this.updateSelectionButton();
}), _defineProperty(_M$View$extend, "updateButton", function updateButton() {}), _M$View$extend));