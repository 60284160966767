import M from 'backbone.marionette';
import T from './player_item.html';
export default M.View.extend({
  tagName: 'li',
  template: _.template(T),
  modelEvents: {
    'change': 'render'
  },
  events: {
    'click': 'onClick'
  },
  onClick: function onClick(event) {
    this.triggerMethod('player:selected', this.model);
    event.preventDefault();
    return false;
  }
});