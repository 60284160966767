import M from 'backbone.marionette';
import lineupeditor from './lineupeditor';
import _ from 'underscore';
export var EditorView = M.View.extend({
  template: _.template('<div id="maineditor"></div>'),
  regions: {
    'maineditor': '#maineditor'
  },
  data: null,
  initialize: function initialize() {
    this.data = this.getOption('data');
  },
  onRender: function onRender() {
    this.updateCurrentEditor();
  },
  updateCurrentEditor: function updateCurrentEditor() {
    this.showChildView('maineditor', new lineupeditor(this.data));
  }
});