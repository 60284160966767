import M from 'backbone.marionette';
import T from './statstable.html';
var stats_by_players = {};
export default M.View.extend({
  className: 'stats-table',
  template: _.template(T),
  mode: null,
  player: null,
  ui: {
    'pj': 'td.pj',
    'b': 'td.b',
    'a': 'td.a',
    'pts': 'td.pts',
    'p82': 'td.p82',
    'p8220': 'td.p8220',
    'mpp': 'td.mpp',
    'diff': 'td.diff',
    'pim': 'td.pim',
    't': 'td.t',
    'mee': 'td.mee',
    'tjm': 'td.tjm',
    'ban': 'td.ban',
    'pan': 'td.pan',
    save: 'td.save',
    avg: 'td.avg',
    pct: 'td.pct',
    wins: 'td.wins',
    losses: 'td.losses',
    ties: 'td.ties',
    so: 'td.so',
    pulled: 'td.pulled',
    statstype: 'button.statstype'
  },
  events: {
    'click @ui.statstype': 'onStatsTypeClick'
  },
  show: function show(player) {
    this.player = player;

    if (parseInt(this.player.get('joueurequipeid')) in stats_by_players) {
      if (stats_by_players[parseInt(this.player.get('joueurequipeid'))].loaded) this.updateTable();else {
        this.listenTo(stats_by_players[parseInt(this.player.get('joueurequipeid'))], 'loaded', _.bind(this.updateTable, this));
      }
    } else {
      stats_by_players[parseInt(this.player.get('joueurequipeid'))] = new Backbone.Collection();
      stats_by_players[parseInt(this.player.get('joueurequipeid'))].loaded = false;
      stats_by_players[parseInt(this.player.get('joueurequipeid'))].comparator = 'order';
      this.listenTo(stats_by_players[parseInt(this.player.get('joueurequipeid'))], 'loaded', _.bind(this.updateTable, this));
      this.fetchStats(parseInt(this.player.get('joueurequipeid')));
    }

    if (this.player.get('G') == '1') {
      this.$el.addClass('goalie');
    } else {
      this.$el.removeClass('goalie');
    }

    this.updateTable();
  },
  fetchStats: function fetchStats(id) {
    $.ajax({
      url: site_url() + '/joueur/getRendementAjax/' + id,
      cache: false,
      success: _.bind(this.onStatsResponse, this)
    });
  },
  onStatsResponse: function onStatsResponse(response) {
    response = JSON.parse(response);
    response = response.rendement;
    var order = 1;
    var id = null;

    for (var s in response) {
      if (id === null) id = parseInt(response[s].playerid);
      response[s].order = order++;
    }

    if (id) {
      stats_by_players[id].loaded = true;
      stats_by_players[id].add(response);
      stats_by_players[id].trigger('loaded');
    }
  },
  updateTable: function updateTable() {
    var p = null;

    if (this.mode == 5 || this.mode == 10) {
      p = {
        'pts': 0,
        'gp': 0,
        'icetime': 0,
        'goals': 0,
        'assist': 0,
        'diff': 0,
        'pim': 0,
        'shots': 0,
        'hits': 0,
        'ppg': 0,
        'pppts': 0,
        save: 0,
        avg: 0,
        pct: 0,
        wins: 0,
        losses: 0,
        ties: 0,
        so: 0,
        pulled: 0
      };
      var c = stats_by_players[parseInt(this.player.get('joueurequipeid'))];

      if (c.length) {
        for (var i = 0; i < this.mode; i++) {
          p.pts += parseInt(c.at(i).get('pts'));
          p.gp++;
          p.icetime += parseFloat(c.at(i).get('icetime'));
          p.goals += parseInt(c.at(i).get('goals'));
          p.assist += parseInt(c.at(i).get('assist'));
          p.diff += parseInt(c.at(i).get('diff'));
          p.pim += parseInt(c.at(i).get('pim'));
          p.shots += parseInt(c.at(i).get('shots'));
          p.hits += parseInt(c.at(i).get('hits'));
          p.ppg += parseInt(c.at(i).get('ppg'));
          p.pppts += parseInt(c.at(i).get('pppts'));
          p.save += parseInt(c.at(i).get('save'));
          p.wins += c.at(i).get('win') == '1' ? 1 : 0;
          p.losses += c.at(i).get('lose') == '1' ? 1 : 0;
          p.ties += c.at(i).get('tie') == '1' ? 1 : 0;
          p.so += c.at(i).get('so') == '1' ? 1 : 0;
          p.pulled += c.at(i).get('pulled') == '1' ? 1 : 0;
        }

        if (p.gp) {
          p.icetime = (p.icetime / p.gp).toFixed(2);
          p.ptspg = (p.pts / p.gp).toFixed(2);
        }

        if (p.shots) p.pct = (p.save / p.shots * 1000).toFixed(2);else p.pct = '-';
        if (p.gp) p.avg = ((p.shots - p.save) / p.gp).toFixed(2);else p.avg = '-';
      }

      p = new Backbone.Model(p);
    } else {
      p = this.player;
    }

    var pts = parseInt(p.get('pts')),
        pj = parseInt(p.get('gp')),
        tjm = parseFloat(p.get('icetime'));
    this.ui.pj.text(parseInt(p.get('gp')));
    this.ui.b.text(parseInt(p.get('goals')));
    this.ui.a.text(parseInt(p.get('assist')));
    this.ui.pts.text(parseInt(p.get('pts')));

    if (pts && pj) {
      var p82 = pts / pj * 82;
      this.ui.p82.text(Math.round(p82));

      if (tjm) {
        this.ui.p8220.text(Math.round(p82 / tjm * 20));
      } else {
        this.ui.p8220.text('');
      }
    } else {
      this.ui.p82.text('');
      this.ui.p8220.text('');
    }

    this.ui.mpp.text(parseFloat(p.get('ptspg')));
    this.ui.diff.text(parseInt(p.get('diff')));
    this.ui.pim.text(p.get('pim') ? parseInt(p.get('pim')) : '0');
    this.ui.t.text(parseInt(p.get('shots')));
    this.ui.mee.text(parseInt(p.get('hits')));
    this.ui.tjm.text(parseFloat(p.get('icetime')));
    this.ui.ban.text(parseInt(p.get('ppg')));
    this.ui.pan.text(parseInt(p.get('pppts')));
    this.ui.save.text(p.get('save'));
    this.ui.avg.text(p.get('avg'));
    this.ui.pct.text(p.get('pct')), this.ui.wins.text(p.get('wins'));
    this.ui.losses.text(p.get('losses'));
    this.ui.ties.text(p.get('ties'));
    this.ui.so.text(p.get('so'));
    this.ui.pulled.text(p.get('pulled'));
  },
  onStatsTypeClick: function onStatsTypeClick(event) {
    switch (this.mode) {
      case 5:
        this.mode = 10;
        this.ui.statstype.text('<10');
        break;

      case 10:
        this.mode = null;
        this.ui.statstype.text('TOT');
        break;

      default:
        this.mode = 5;
        this.ui.statstype.text('<5');
        break;
    }

    this.updateTable();
  }
});