import M from 'backbone.marionette';
import LineupPlayerItem from './linupplayeritem';
var EstradeItem = M.View.extend({
  tagName: 'li',
  className: 'list-group-item',
  template: _.template('<table><tr><td><span class="c"></span></td><td><button class="btn btn-default btn-xs delete">X</button></td></tr>'),
  regions: {
    'player': 'span.c'
  },
  ui: {
    'delete_button': 'button.delete'
  },
  events: {
    'click button.delete': 'onDeleteClick'
  },
  onRender: function onRender() {
    this.showChildView('player', new LineupPlayerItem({
      model: this.model
    }));
    if (parseInt(this.model.get('lineup_status')) != 100) this.ui.delete_button.hide();
  },
  onDeleteClick: function onDeleteClick(event) {
    if (parseInt(this.model.get('lineup_status')) == 100) this.model.set('lineup_status', '0');
    document.lue.model.trigger('change');
  }
});
export default M.CollectionView.extend({
  tagName: 'ul',
  className: 'list-group',
  childView: EstradeItem,
  collectionEvents: {
    'change': 'render'
  },
  filter: function filter(model, index, collection) {
    switch (parseInt(model.get('lineup_status'))) {
      case 100:
      case 101:
      case 102:
      case 103:
      case 104:
      case 105:
      case 106:
      case 201:
      case 202:
      case 203:
      case 204:
      case 205:
      case 206:
        return true;

      default:
        return parseInt(model.get('susp')) > 0;
    }
  }
});