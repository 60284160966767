import M from 'backbone.marionette';
var cp = M.Object.extend({
  gauge: [{
    value: 0,
    r: 180,
    g: 0,
    b: 0
  }, {
    value: 50,
    r: 225,
    g: 116,
    b: 0
  }, {
    value: 65,
    r: 203,
    g: 203,
    b: 0
  }, {
    value: 75,
    r: 3,
    g: 169,
    b: 11
  }, {
    value: 85,
    r: 0,
    g: 202,
    b: 215
  }, {
    value: 99,
    r: 0,
    g: 55,
    b: 180
  }],
  stats: function stats(p) {
    p = _.isNumber(p) && p > 0 ? p : 0;
    var from = null,
        to = null;

    for (var i in this.gauge) {
      if (p >= this.gauge[i].value) from = this.gauge[i];
      if (p <= this.gauge[i].value) to = this.gauge[i];
      if (from && to) break;
    }

    if (!to) to = this.gauge[this.gauge.length - 1];
    var s = p - from.value;
    if (to.value - from.value > 0) s = s / (to.value - from.value);
    return this.RGHB2HEX(Math.round((to.r - from.r) * s + from.r), Math.round((to.g - from.g) * s + from.g), Math.round((to.b - from.b) * s + from.b));
  },
  statsFont: function statsFont(p) {},
  ov: function ov(p) {},
  ovFont: function ovFont(p) {},
  NUM2HEX: function NUM2HEX(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  },
  RGHB2HEX: function RGHB2HEX(r, g, b) {
    return "#" + this.NUM2HEX(r) + this.NUM2HEX(g) + this.NUM2HEX(b);
  },
  legende: function legende() {
    var out = 'Légende : ';

    for (var i in this.gauge) {
      out += '<span class="coloredov" style="background-color: ' + this.RGHB2HEX(this.gauge[i].r, this.gauge[i].g, this.gauge[i].b) + '">' + this.gauge[i].value + '</span> ';
    }

    return out;
  }
});
export default new cp();