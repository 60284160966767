import M from 'backbone.marionette';
import T from './linupplayeritem.html';
import colorPalette from './colorpalette';
export default M.View.extend({
  tagName: 'a',
  attributes: {
    'href': '#'
  },
  events: {
    'click': 'onClick'
  },
  serializeData: function serializeData() {
    var out = this.model.toJSON();
    out.logo = this.getOption('showLogos');
    return out;
  },
  modelEvents: {
    'change': 'render'
  },
  className: 'lineup-player-item',
  template: _.template(T),
  ui: {
    'coloredov': '.coloredov'
  },
  initialize: function initialize() {
    this.listenTo(document.lue, 'playerselected', _.bind(this.checkCurrentPlayer, this));
  },
  onRender: function onRender() {
    this.ui.coloredov.css('background-color', colorPalette.stats(parseInt(this.model.get('webov'))));
    this.checkCurrentPlayer();

    if (!document.lue.canPlay(this.model)) {
      this.$el.addClass('cantplay');
    } else {
      this.$el.removeClass('cantplay');
    }

    if (this.model.get('C') == '1') this.$el.addClass('pos-C');
    if (this.model.get('AD') == '1') this.$el.addClass('pos-AD');
    if (this.model.get('AG') == '1') this.$el.addClass('pos-AG');
    if (this.model.get('D') == '1') this.$el.addClass('pos-D');
    if (this.model.get('G') == '1') this.$el.addClass('pos-G');
    if (parseInt(this.model.get('roster_status')) == 0) this.$el.addClass('ahl');else this.$el.addClass('vhlq');
  },
  onClick: function onClick(event) {
    event.preventDefault();
    document.lue.playerSelection(this.model, this.getOption('pos'));
  },
  checkCurrentPlayer: function checkCurrentPlayer() {
    if (document.lue.selected_player && document.lue.selected_player == this.model) {
      this.$el.addClass('active');
    } else this.$el.removeClass('active');
  }
});