import M from 'backbone.marionette';
import T from './ovview.html';
import colorPalette from './colorpalette';
export default M.View.extend({
  template: _.template(T),
  attrs: null,
  collection: null,
  ui: {
    'rows': 'tbody',
    'heading': '.heading'
  },
  initialize: function initialize() {
    this.attrs = ['IT', 'SP', 'ST', 'EN', 'DU', 'DI', 'SK', 'PA', 'PC', 'DF', 'SC', 'EX', 'LD', 'webov'];
    this.collection = this.getOption('collection');
  },
  onRender: function onRender() {
    this.ui.heading.append('<th></th>');

    for (var i in this.attrs) {
      this.ui.heading.append('<th>' + this.attrs[i] + '</th>');
    }

    if (this.collection) {
      for (var i = 0; i < this.collection.length; i++) {
        this.appendrow(this.collection.at(i));
      }
    } else if (this.model) {
      this.appendrow(this.model);
    }
  },
  appendrow: function appendrow(model) {
    var p = $('<tr></tr>');
    this.ui.rows.append(p);
    $(p).append('<td class="type">' + (model.get('type') ? model.get('type') : '') + '</td>');

    for (var i in this.attrs) {
      $(p).append('<td style="background-color: ' + colorPalette.stats(parseInt(model.get(this.attrs[i]))) + '">' + Math.round(model.get(this.attrs[i])) + '</td>');
    }
  }
});