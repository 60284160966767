import moment from 'moment';
import { TestView } from './views/test';
import { EditorView } from './views/gmeditor/editor';
import { Config } from './config';
import { CurrentUser } from './currentuser';
import { UserName } from './views/username';
import { TimeSpan } from './views/timespan';
moment.locale('fr');
var modules = new Map();
modules.set('test', TestView);
modules.set('gmeditor', EditorView);
modules.set('username', UserName);
modules.set('timespan', TimeSpan);
Config.initialize();
CurrentUser.initialize();

function camelCased(myString) {
  return myString.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

function bootstrapContent(el) {
  if (!el) return;
  var elements = el.querySelectorAll('[data-module]');
  elements.forEach(function (child) {
    var module_name = child.attributes.getNamedItem('data-module');

    if (module_name) {
      var v = modules.get(module_name.value);

      if (v) {
        child.removeAttribute('data-module');
        child.setAttribute('data-bootstraped-module', module_name.value);
        var moduleOptions = {
          el: child
        };
        Array.from(child.attributes).forEach(function (attribute) {
          if (attribute.specified && attribute.name.substr(0, 5) == 'data-') {
            moduleOptions[camelCased(attribute.name.substr(5))] = attribute.value;
          }
        });
        new v(moduleOptions).render();
      } else {
        console.error('Module ' + module_name + ' not found :(');
      }
    }
  });
}

$(function () {
  bootstrapContent(document.querySelector('body'));
});